<app-project-page-preview sclass="banner_alty" imgsrc='assets/img/alty.com.png' title="Alty.com" desc="Crypto Chatbot for Whatsapp, Telegram & Discord" tags="web | development | wordpress" routerLink="/alty" onclick="openPage()"></app-project-page-preview>

<section class="content" role="main">

  <app-project-page-details
    details="
    <p><b>Project URL: <a href='https://alty.com/' rel='noopener noreferrer' target='_blank'>https://alty.com/</a></b></p>
    <ul>
      <li>Implemented the design of version 2 and version 3 of the alty.com.</li>
      <li>Improved the SEO score of alty.com from no presence at all to number 4 result on Google, and number 2 result on Bing and Yahoo.</li>
      <li>Designed and implemented several pages of the Alty website.</li>
      <li>Used Advanced Custom fields to create custom modules for WordPress; HTML, PHP, JS, CSS.</li>
      <li>Used Elementor to implement some pages.</li>
      <li>Worked with Bitrix24 to manage day to day tasks.</li>
      <li>Used Stonly.com to create guides for alty.com</li>
    </ul>">

  </app-project-page-details>

  <app-show-project-previews
    [web_array]="['patriot', 'emce', 'fch', 'music', 'scriptcase', 'webalert', 'we_plantin']"
    [mobile_array]="['cimh_app', 'crowdsourced']"
    [other_array]="['pest_disease', 'filebound', 'cid']"
  >
  </app-show-project-previews>
</section>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-web-music',
  templateUrl: './page-web-music.component.html',
  styleUrls: ['./page-web-music.component.css']
})
export class PageWebMusicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-mobile-cimh-app',
  templateUrl: './page-mobile-cimh-app.component.html',
  styleUrls: ['./page-mobile-cimh-app.component.css']
})
export class PageMobileCimhAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

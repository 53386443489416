<app-project-page-preview sclass="banner_cimh_pest_disease" imgsrc='assets/img/pest_disease_python1.png' title="CIMH Pest & Disease Risk Index Script" desc="Small Python Application" tags="python" routerLink="/cimh-pest_disease" onclick="openPage()"></app-project-page-preview>

<section class="content" role="main">

  <app-project-page-details
    details="
    <ul>
      <li>Wrote Python scripts to produce the risk index level for Black Sigatoka disease in banana crops, and the WhiteFly models.</li>
    </ul>">

  </app-project-page-details>


  <app-show-project-previews
    [web_array]="['alty', 'patriot', 'emce', 'fch', 'music', 'scriptcase', 'webalert', 'we_plantin']"
    [mobile_array]="['cimh_app', 'crowdsourced']"
    [other_array]="['filebound', 'cid']"
  >
  </app-show-project-previews>
</section>

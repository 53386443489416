import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-other-cimh-cid',
  templateUrl: './page-other-cimh-cid.component.html',
  styleUrls: ['./page-other-cimh-cid.component.css']
})
export class PageOtherCimhCidComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<section class="bkgd_img toolbars {{sclass}}" role="banner">

  <div class="flexbox">

    <div class="project_container">
      <div class="project_text">
        <div class="title">{{ title }}</div>
        <div class="desc">{{ desc }}</div>
        <div class="tags">{{ tags }}</div>
      </div>
    </div>
  </div>
</section>


<header class="toolbars" role="banner">
  <!-- <svg class="arc">
    <path
       class="filled_arc"
       d="M 0.10025228,0.08371583 V 297 h 0.0905567 A 497.05888,302.66461 0 0 1 496.79098,0.08371583 Z
       M 497.05906772,0 a 497.05888,302.66461 0 0 1 9.04095,0.05001038 V 0.08371583 Z"
       transform="scale(2)" />
  </svg> -->

  <div class="toolbar">
    <div class="inner">
      <nav>
        <a href="/"><span><img class="logo" src="assets/img/S3_white.png" alt="logo"></span></a>
        <ul>
          <a href="#projects"><li>Projects</li></a>
          <a href="#about_me"><li>About Me</li></a>
          <a href="#skills_abilities"><li>Skills</li></a>
          <a href="#contact"><li>Contact</li></a>
          <a href="../assets/pdf/Shakib Small CV w Photo.pdf" class="cv_link" target="_blank"><li>CV</li></a>
        </ul>
      </nav>
    </div>
  </div>

  <div id="hamburger_container" onclick="open_hamburger_menu();">
    <svg id="hamburger" viewBox="0 0 100 100">
      <circle class="circle" cx="50" cy="50" r="50"></circle>
      <line class="line-1" x1="30" y1="40" x2="70" y2="40" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-linecap="round" stroke-width="3"></line>
      <line class="line-1" x1="30" y1="50" x2="70" y2="50" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-linecap="round" stroke-width="3"></line>
      <line class="line-1" x1="30" y1="60" x2="70" y2="60" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-linecap="round" stroke-width="3"></line>
    </svg>
  </div>

  <div class="side_bar close" id="side_bar">
      <nav>
        <!-- <a href="/" class="side_bar_top"><img class="logo" src="assets/img/S3_white.png" alt="logo"></a> -->
        <ul>
          <a href="#projects"><li>Projects</li></a>
          <a href="#about_me"><li>About Me</li></a>
          <a href="#skills_abilities"><li>Skills</li></a>
          <a href="#contact"><li>Contact</li></a>
          <a href="../assets/pdf/Shakib Small CV w Photo.pdf" class="cv_link" target="_blank"><li>CV</li></a>
        </ul>
      </nav>
  </div>

</header>



<router-outlet></router-outlet>



<!-- Footer -->
<footer>
    <div>Copyright © Shakib S Small</div>
    <!-- <a href="#" target="_blank" rel="noopener">
      <div class="github-star-badge">
          <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/></svg>
      </div>
    </a> -->
    <a href="#" target="_blank" rel="noopener">
      <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="#1976d2"/><path d="M0 0h24v24H0z" fill="none"/></svg>
    </a>
</footer>

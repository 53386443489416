<section class="bkgd_img toolbars {{sclass}}" role="banner">
  <div class="hey_there">
    <h3 id="hey_im">{{hey_im}}</h3>
    <div>
      <h1>
        <p>
          <strong id="name">{{text}}</strong>
        </p>
      </h1>
    </div>
    <a href="{{link}}" class="cv_button"*ngIf="cv_button" target="_blank">{{cv_button}}</a>
  </div>
</section>

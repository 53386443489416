
<div class="form_wrapper" scrollout-once>
  <form class="contact-form" [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">
    <div class="input-group">
      <input class="contact_input" type="text" name="name" formControlName="name" placeholder=" ">
      <label for="name">name</label>
    </div>
    <div class="input-group">
      <input class="contact_input" type="email" name="email" formControlName="email" placeholder=" ">
      <label for="email">email</label>
    </div>
    <div class="input-group last">
      <textarea class="contact_input" type="text" name="message" formControlName="message" placeholder=" "></textarea>
      <label for="message">message</label>
    </div>
    <div class="bottom-area">
      <div class="recapcha"></div>
      <button type="button" type="submit">send</button>
    </div>
  </form>
</div>

<!-- <div class="project_container" onclick="openPage()" [routerLink]="'/project-page'"> -->
<div class="project_container">
  <!-- <img src="{{ imgsrc }}"/> -->
  <div class="img_wrap" style="background-image: url('{{ imgsrc }}');"></div>
  <div class="project_text">
    <div class="title">{{ title }}</div>
    <div class="desc">{{ desc }}</div>
    <div class="tags">{{ tags }}</div>
  </div>
</div>
